import React, { useState,useRef, useContext  } from 'react';
import axios from "axios";
import { withRouter, useNavigate } from "react-router-dom";

import "../App.css";

import { LOGIN_URL, login } from '../components/requests';

const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;


const LoginForm = () => { 
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const navigateThere = useNavigate();

  // const { setAuth } = useContext(AuthContext);
    const userRef = useRef();
    const errRef = useRef();

    // const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    //handle login
    const handleLogin = async (e) => {
      e.preventDefault();
      setErrorMessage("");
    login({"email":email,"password":password}).then(
      (response) => {

        if (!response.status) {
          setErrorMessage("Başarısız giriş!")
        }else if (response.status===200) {
          if (response.data.user){
            let user_info = response.data.user;
            // console.log(response?.data.user)
            var parsedUser = JSON.parse(user_info)
            if (parsedUser.user_type==9) {
              
              localStorage.setItem('isLoggedIn', JSON.stringify('true'));
              localStorage.setItem('first_name', JSON.stringify(parsedUser.first_name));
              localStorage.setItem('last_name', JSON.stringify(parsedUser.last_name));
              localStorage.setItem('phone', JSON.stringify(parsedUser.phone));
              localStorage.setItem('user_type', JSON.stringify(parsedUser.user_type));
              localStorage.setItem('user_id', JSON.stringify(parsedUser.id));

              navigateThere('/yetkili-etkinliklerim')
              setSuccess(true);
              setErrorMessage('')
            }else{
              setErrorMessage("Başarısız giriş! Yetkili kişi olduğunuza emin olun")
            }

            }else{setErrorMessage("Başarısız giriş!")}
        }
        
      }
    )

    }

  const handleLoginold = async (e) => {
    e.preventDefault();
    // Replace this part with your actual login logic
    // if (username === 'exampleuser' && password === 'examplepassword') {
    //   // Successful login logic (e.g., redirect to dashboard)
    //   console.log('Login successful!');
    // } else {
    //   setErrorMessage('Yanlış bilgileri girdiniz.');


   
    
    // if button enabled with JS hack
    // const v1 = USER_REGEX.test(name);
    // const v2 = PWD_REGEX.test(pwd);
    // if (!v1 || !v2) {
    //   console.log('There was an error');
    //     setErrMsg("Invalid Entry");
    //     return;
    // }



    
  };

  return (
    
    <div  className="h-screen flex items-center justify-center bg-gray-400">
      {/* <CContainer> */}
      <div className='bg-blue-100 rounded-md w-96 h-52 pt-7'>

            <h2 style={{color:'black'}} className=''>Karekod Okutucu Girişi</h2> <br/>
        <form onSubmit={handleLogin}>
         
            <input className=''
             type="email" id="username" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Mail adresi (örnek@gmail.com)" />
            

          <div className="mt-3" >
            {/* <Form.Label>Şifre</Form.Label> */}
            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Şifre" />
          </div>
        
          <button className='mt-2 bg-blue-600 rounded-md w-20' type="submit">
            Giriş Yap
          </button>
        </form>
        {errorMessage && <p className='text-red-500 bg-red-200 mt-2'>{errorMessage}</p>}
    {/* </CContainer> */}
   
      </div>

    </div>
    
    
    
   
  );
};

export default LoginForm;
